<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        The reactants for an acid-base reaction are shown below. Answer each of the three questions
        regarding this reaction.
      </p>

      <p class="mb-5 pl-8">
        <chemical-latex content="C6H5NH2(aq) + HClO2(aq) <=>" />
      </p>

      <p class="mb-2">
        a) Write the FULL balanced equation for the reaction. Include any charges (if any) and the
        phase for all chemical species.
      </p>

      <chemical-notation-input
        v-model="inputs.balancedEqn"
        dense
        :show-note="false"
        class="mb-7"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        b) Using the data available in the Appendices of the textbook,
        <a
          href="https://ecampusontario.pressbooks.pub/genchemforgeegees/"
          rel="noopener noreferrer"
          target="_blank"
          >Gen Chem for Gee Gees</a
        >, determine the values of
        <stemble-latex content="$\text{pK}_\text{a}$" />
        for each acid in the reaction and the
        <stemble-latex content="$\text{pK}_\text{b}$" />
        for each base in the reaction.
      </p>

      <calculation-input
        v-model="inputs.pKaReact"
        class="mb-2"
        prepend-text="$\text{pK}_\text{a}\text{ of Reactant Acid:}$"
        :disabled="!allowEditing"
      />

      <calculation-input
        v-model="inputs.pKbReact"
        class="mb-2"
        prepend-text="$\text{pK}_\text{b}\text{ of Reactant Base:}$"
        :disabled="!allowEditing"
      />

      <calculation-input
        v-model="inputs.pKaProd"
        class="mb-2"
        prepend-text="$\text{pK}_\text{a}\text{ of Product Acid:}$"
        :disabled="!allowEditing"
      />

      <calculation-input
        v-model="inputs.pKbProd"
        class="mb-7"
        prepend-text="$\text{pK}_\text{b}\text{ of Product Base:}$"
        :disabled="!allowEditing"
      />

      <p class="mb-n3">
        c) Based on the
        <stemble-latex content="$\text{pK}_\text{a}$" />
        and
        <stemble-latex content="$\text{pK}_\text{b}$" />
        of the reactants and products, predict which side of the reaction would be favoured.
      </p>

      <v-radio-group v-model="inputs.favouredSide" :disabled="!allowEditing" class="ml-6 mb-n5">
        <div v-for="option in options" :key="option.value">
          <v-radio class="d-inline-block mb-1" :value="option.value" />
          <stemble-latex :content="option.expression" />
        </div>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import ChemicalLatex from '../displayers/ChemicalLatex';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput.vue';

export default {
  name: 'Question469a',
  components: {
    ChemicalNotationInput,
    CalculationInput,
    ChemicalLatex,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        balancedEqn: null,
        pKaReact: null,
        pKbReact: null,
        pKaProd: null,
        pKbProd: null,
        favouredSide: null,
      },
      options: [
        {expression: '$\\text{Reactant favoured}$', value: 'reactFavoured'},
        {expression: '$\\text{Product favoured}$', value: 'prodFavoured'},
        {expression: '$\\text{Neither side favoured}$', value: 'neither'},
      ],
    };
  },
};
</script>
